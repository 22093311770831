import React, { useState, useEffect } from "react";
import call from "../assets/call.svg";
import mail from "../assets/mail.svg";
import place from "../assets/place.svg";
import logo from "../assets/logo-display.svg";
import { FaLocationDot } from "react-icons/fa6";
import GoogleMap from "../components/GoogleMap";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [isSmScreen, setIsSmScreen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleResize = () => {
    setIsSmScreen(window.innerWidth < 768);
  };

  const toastNotification = () => {
    toast.success("Inquiry has been sent successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        toastNotification();
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send inquiry. Please try again later.");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="mt-10">
      <ToastContainer />
      <div className="flex items-center justify-center mb-1">
        <FaLocationDot fontSize="2.2rem" color="#01AAE8" />
        <p className="font-bold text-navbarBlue text-[22px] lg:text-[44px]">
          Locate Us Now
        </p>
      </div>
      <div className="relative text-center mb-10">
        <GoogleMap />
      </div>
      <div className="bg-white relative mb-10 md:mb-0">
        <div
          className="bg-numbers w-full absolute top-0 z-0"
          style={{ height: isSmScreen ? "100%" : "600px" }}
        ></div>
        <div className="block md:flex px-5 lg:px-10 py-10 relative">
          <div
            className="bg-white md:w-1/2 w-full mr-5 px-2 lg:px-8 pt-8"
            style={{
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
            }}
          >
            <div className="flex items-center mb-5">
              <img src={logo} alt="Logo" />
              <div className="font-bold ml-3 text-[24px] lg:text-[36px]">
                Contact Us
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
                required
              />
              <input
                type="text"
                name="subject"
                placeholder="Enter Subject"
                value={formData.subject}
                onChange={handleChange}
                className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
                required
              />
              <textarea
                name="message"
                placeholder="Write Your Concern"
                value={formData.message}
                onChange={handleChange}
                className="bg-inputs w-full py-2 px-3 rounded-md my-4 h-[300px] text-13px lg:text-[20px]"
                required
              />
              <button
                type="submit"
                className="text-[13px] lg:text-[25px] bg-gradient-to-b from-home to-linear text-white w-full py-2 mb-8 rounded-md border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:from-white hover:to-white hover:border-border"
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="md:w-1/2 w-full md:ml-5 mt-5 md:mt-0 text-white">
            <div className="font-bold text-[16px] lg:text-[28px]">
              Contact with us
            </div>
            <div className="font-bold text-[22px] lg:text-[44px]">
              let's work together?
            </div>
            <div className="text-base mb-5">
              Get in touch with us to discuss how we can help you achieve your
              goals.
            </div>
            <div className="mt-10 lg:mt-20">
              <div className="flex my-8 items-center">
                <div className="bg-white rounded-md" style={{ width: "60px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)" }}>
                  <img src={call} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>HAVE ANY QUESTION?</div>
                  <div>+91 9325901176</div>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <div className="bg-white rounded-md" style={{ width: "60px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)" }}>
                  <img src={mail} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>SEND EMAIL</div>
                  <div>santosh@abssoftware.in</div>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <div className="bg-white rounded-md" style={{ width: "60px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)" }}>
                  <img src={place} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>VISIT ANYTIME</div>
                  <div>Sterling Center, 2nd Floor office no 231, Pune Camp-411001</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
